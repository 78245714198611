// ./common/PrivacyPolicy.js

import React from 'react';
import ClickableDiv from './ClickableDiv';
import LocalizedText from './LocalizedText';

const PrivacyPolicy = () => {
  const PrivacyPolicyClick = () => {
    // Handle Privacy Policy click
  };

  return (
    <div className="header-button flex-column">
      <ClickableDiv onClick={PrivacyPolicyClick}>
        <LocalizedText id="privacyPolicy" />
      </ClickableDiv>
    </div>
  );
};

export default PrivacyPolicy;
