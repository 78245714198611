import React, { useState, useRef, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LocalizedText from './LocalizedText';

const SearchBySpeciality = ({ specialities, selectedSpeciality, setSelectedSpeciality }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSpecialityChange = (event) => {
    const { value } = event.target;
    const selectedObject = specialities.find(spec => spec.name === value);
    setSelectedSpeciality(selectedObject); // Update selectedSpeciality with the selected object
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isSpecialitySelected = selectedSpeciality && selectedSpeciality.id !== 'default';

  return (
    <FormControl
      fullWidth
      className={isSpecialitySelected || isDropdownOpen ? 'selected-speciality' : ''}
      ref={dropdownRef}
    >
      <InputLabel id="speciality-select-label">
        <LocalizedText id="selectSpeciality" />
      </InputLabel>
      <Select
        labelId="speciality-select-label"
        id="speciality-select"
        value={selectedSpeciality ? selectedSpeciality.name : ''}
        onChange={handleSpecialityChange}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        open={isDropdownOpen}
      >
        <MenuItem value="">
          <LocalizedText id="selectSpeciality" />
        </MenuItem>
        {specialities.map((speciality) => (
          <MenuItem key={speciality.id} value={speciality.name}>
            {speciality.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SearchBySpeciality;
