// CookieConsent.js

import React from 'react';
import LocalizedText from './common/LocalizedText';

const CookieConsent = () => {
  return (
    <div>
      <h1>
        <LocalizedText id="cookieConsent" />
      </h1>
      {/* Add content for Cookie Consent page */}
    </div>
  );
};

export default CookieConsent;
