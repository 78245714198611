import React from 'react';
import { Box, Typography } from '@mui/material';
import LocalizedText from '../common/LocalizedText';

const PaymentDetails = ({ onBack, onConfirm, disablePayButton }) => {
  const price = 1500;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        <LocalizedText id="paymentTitle" />
      </Typography>

      <Typography variant="body1" gutterBottom>
        <LocalizedText id="Price" />
      </Typography>

      <Typography variant="body2" gutterBottom>
        <LocalizedText id="paymentAmount" />: {price !== undefined ? 1000 : ''}
      </Typography>

      {/* Payment explanation */}
      <Typography variant="body2" gutterBottom>
        <LocalizedText id="paymentDetailsExplanation" />
      </Typography>
    </Box>
  );
};

export default PaymentDetails;
