// PrivacyPolicy.js

import React from 'react';
import LocalizedText from "../Pages/common/LocalizedText";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1><LocalizedText id="privacyPolicy"/></h1>
      {/* Add content for Privacy Policy page */}
    </div>
  );
};

export default PrivacyPolicy;
