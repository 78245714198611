// src/pages/ResponseFailure.js
import React from 'react';

const ResponseFailure = () => {
    return (
        <div>
            <h1>Payment Failed</h1>
            <p>There was an issue with your payment. Please try again.</p>
        </div>
    );
};

export default ResponseFailure;
