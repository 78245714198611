import React, { useContext } from 'react';
import { LocalizationContext } from './LocalizationContext'; // Assuming you have a named export for LocalizationContext
import './css/LocalizedText.scss';

const LocalizedText = ({ id, className }) => {
  const { translations, language } = useContext(LocalizationContext);

  // Normalize the id to lowercase for case-insensitive matching
  const normalizedId = id.toLowerCase();

  // Find the translation for the given language, if available
  const languageTranslations = translations[language] || {};
  
  // Normalize translation keys and find the matching text
  const textKey = Object.keys(languageTranslations).find(key => key.toLowerCase() === normalizedId);
  const text = textKey ? languageTranslations[textKey] : id;

  // Render based on the presence of className
  if (className) {
    return <span className={className}>{text}</span>;
  }

  return text;
};

export default LocalizedText;
