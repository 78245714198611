import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import LocalizedText from './LocalizedText';

const MiniDoctorInfo = ({ doctor, requestFor = false}) => {
  const specializations = Array.isArray(doctor.specializations) ? doctor.specializations : [];
  
  return (
    <Box
      sx={{
        display: 'inline-flex',
        // alignItems: 'center',
        // backgroundColor: '#f5f5f5',
        // borderRadius: 1,
        padding: '10px 20px',
        // boxShadow: 1,
        fontSize: '0.75rem', // Smaller font size for compact view
        lineHeight: '1.2',
      }}
    >
      {requestFor && <LocalizedText id="requestFor" className="smallText" />}
      <Avatar
        alt={doctor.name}
        sx={{ width: 24, height: 24, marginRight: 1 }}
        src={`${process.env.REACT_APP_API_BASE_URL}/doctor/${doctor.id}/profilepicture` || "https://mmc.qa/wp-content/uploads/2020/09/doctor-avatar.jpg"}
      />
      <Typography variant="body2" sx={{ marginRight: 0.5 }}>
        {doctor.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        ({specializations.join(', ')})
      </Typography>
    </Box>
  );
};

export default MiniDoctorInfo;
