import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stepper, Step, StepLabel, Button } from '@mui/material';
import RequestDetails from './RequestDetails';
import PersonalInfo from './PersonalInfo';
import Preview from './Preview';
import './css/Stepper.scss'; // Import your SCSS file
import LocalizedText from '../common/LocalizedText';
import MiniDoctorCard from '../common/MiniDoctorCard';
import './css/Request.scss';
import PaymentDetails from './PaymentDetails';
import { EkimApiClient } from '../../HttpClient/EkimApiClient';

const steps = [
  'RequestDetails',
  'PersonalInfo',
  'Preview',
  'PaymentDetails' // Add this line for the new step
];
const gatewayUrl = process.env.REACT_APP_PAYMENT_GATEWAY_URL || "https://torus-stage-halkbankmacedonia.asseco-see.com.tr/fim/est3Dgate";

const Request = () => {
  const location = useLocation();
  const doctor = location.state?.doctor || {};
  const [step, setStep] = useState(0);
  const [paymentHtmlContent, setPaymentHtmlContent] = useState('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const [formData, setFormData] = useState({
    doctorId: doctor.id,
    title: '',
    problemDescription:'',
    selectedSymptoms: [],
    uploadedFiles: [],
    firstName: '',
    lastName: '',
    age: '',
    height: '',
    weight: '',
    placeOfLiving: '',
    temperature: '',
    bloodPressureLow: '',
    bloodPressureHigh: '',
    bloodSugar: '',
    bloodIron: '',
    respiratoryLevel: '',
    additionalInfo: '',
    email: ''
  });

  const [isRequestDetailsValid, setIsRequestDetailsValid] = useState(true);

  const handleNext = () => {
    if (step === 0) {
      // Move to next step only if RequestDetails is valid
      if (isRequestDetailsValid) {
        setStep(prevStep => prevStep + 1);
      }
    } else if (step === 1) {
      setStep(prevStep => prevStep + 1);
    } else if (step === 2) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setStep(prevStep => prevStep - 1);
  };

  const handlePreview = () => {
    setStep(2); // Go to Preview step when user clicks Preview button
  };

  const handleSaveAndContinue = async () =>{
    await EkimApiClient.submitRequestAsync(formData);
    setStep(3);
  }

  const handlePayment = async () => {
    var parameters = await EkimApiClient.initializePaymentGetAsync(formData.email, formData.firstName + " " + formData.lastName, "");

    // Create and submit the form programmatically
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = gatewayUrl;

    Object.keys(parameters).forEach(index => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = parameters[index].key;
      input.value = parameters[index].value;
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  const handleConfirm = () => {
    console.log('Form data confirmed:', formData);
  };

  const handleFormDataChange = (data) => {
    setFormData(prev => ({ ...prev, ...data }));
  };

  const handleStepClick = (index) => {
    if (index <= step) {
      setStep(index);
    }
  };

  const handleValidateRequestDetails = (validationStatus) => {
    setIsRequestDetailsValid(validationStatus);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Stepper
        activeStep={step}
        alternativeLabel
        className="custom-stepper"
      >
        {steps.map((label, index) => (
          <Step key={index} onClick={() => handleStepClick(index)}>
            <StepLabel>{<LocalizedText id={label} /> }</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="requestContainer">
        {doctor && (
          <>
            <MiniDoctorCard  doctor={doctor} key={doctor.id} requestFor={true} />
          </>
        )}
      </div>

      {step === 0 && (
        <RequestDetails
          formData={formData}
          onNext={handleNext}
          onFormDataChange={handleFormDataChange}
          onValidate={handleValidateRequestDetails} // Pass the validation handler
        />
      )}
      {step === 1 && (
        <PersonalInfo
          formData={formData}
          onBack={handleBack}
          onPreview={handlePreview}
          onFormDataChange={handleFormDataChange}
        />
      )}
      {step === 2 && (
        <Preview
          formData={formData}
          onBack={handleBack}
          onConfirm={handleNext} // Move to Payment step
          disableSaveButton={setIsSaveButtonDisabled}
          onFormDataChange={handleFormDataChange}
        />
      )}
      {step === 3 && (
        <PaymentDetails
          onBack={handleBack}
          onConfirm={handleConfirm} // Finalize form submission
        />
      )}

      <Box sx={{ marginTop: 3, display: 'flex', justifyContent: 'space-between' }}>
        {step > 0 && (
          <Button onClick={handleBack} color="inherit">
            <LocalizedText id="Back" />
          </Button>
        )}
        {step < steps.length - 3 && (
          <Button
            size="medium"
            sx={{
              backgroundColor: '#282c34',
              color: 'white',
              '&:hover': { backgroundColor: '#404854' },
            }}
            onClick={handleNext}
          >
            <LocalizedText id="next" />
          </Button>
        )}
        {step === steps.length - 3 && (
          <Button
            size="medium"
            sx={{
              backgroundColor: '#282c34',
              color: 'white',
              '&:hover': { backgroundColor: '#404854' },
            }}
            onClick={handlePreview} // Go to Preview step
          >
            <LocalizedText id="preview" />
          </Button>
        )}
        {step === steps.length - 2 && (
          <Button
            size="medium"
            sx={{
              backgroundColor: isSaveButtonDisabled ? 'transparent' : '#282c34',
              color: isSaveButtonDisabled ? 'primary.main' : 'white',
              border: isSaveButtonDisabled ? '1px solid' : 'none',
              '&:hover': {
                backgroundColor: isSaveButtonDisabled ? 'transparent' : '#404854',
              },
            }}
            onClick={handleSaveAndContinue} // Use the new handleConfirm function
            disabled={isSaveButtonDisabled}
          >
            <LocalizedText id="saveAndContinue" />
          </Button>
        )}
        {step === steps.length - 1 && (
          <Button
            size="medium"
            sx={{
              backgroundColor: '#282c34',
              color: 'white',
              '&:hover': { backgroundColor: '#404854' },
            }}
            onClick={handlePayment} // Use the new handleConfirm function
          >
            <LocalizedText id="pay" />
          </Button>
        )}
      </Box>
      <div
            dangerouslySetInnerHTML={{ __html: paymentHtmlContent }}
        />
    </Box>
  );
};

export default Request;
