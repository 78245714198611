import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Chip, Autocomplete, useMediaQuery, useTheme, Grid } from '@mui/material';
import LocalizedText from '../common/LocalizedText';
import { v4 as uuidv4 } from 'uuid';
import FilePreviews from './FilePreviews';

const RequestDetails = ({ formData, onNext, onFormDataChange, onValidate }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [titleChars, setTitleChars] = useState(0);
  const [problemDescriptionChars, setproblemDescriptionChars] = useState(0);
  const [titleError, setTitleError] = useState(false);
  const [problemDescriptionError, setproblemDescriptionError] = useState(false);

  const maxTitleChars = 100;
  const maxproblemDescriptionChars = 6000;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTitleChars(formData.title.length);
      setproblemDescriptionChars(formData.problemDescription.length);
    }, 500);

    return () => clearTimeout(timer);
  }, [formData.title, formData.problemDescription]);

  useEffect(() => {
    if (onValidate) {
      onValidate(validateFields());
    }
  }, [titleError, problemDescriptionError, formData.title, formData.problemDescription]);

  const validateFields = () => {
    let valid = true;
    if (formData.title.trim().length === 0 || formData.title.length > maxTitleChars) {
      setTitleError(true);
      valid = false;
    } else {
      setTitleError(false);
    }

    if (formData.problemDescription.trim().length === 0 || formData.problemDescription.length > maxproblemDescriptionChars) {
      setproblemDescriptionError(true);
      valid = false;
    } else {
      setproblemDescriptionError(false);
    }

    return valid;
  };

  const handleAddSymptom = () => {
    if (selectedSymptom && !formData.selectedSymptoms.includes(selectedSymptom.name)) {
      onFormDataChange({
        selectedSymptoms: [...formData.selectedSymptoms, selectedSymptom.name],
      });
      setSelectedSymptom(null);
      setInputValue('');
    } else if (inputValue && !formData.selectedSymptoms.includes(inputValue)) {
      const newSymptom = { id: uuidv4(), name: inputValue };
      setSymptoms([...symptoms, newSymptom]);
      onFormDataChange({
        selectedSymptoms: [...formData.selectedSymptoms, inputValue],
      });
      setInputValue('');
    }
  };

  const handleRemoveSymptom = (symptom) => {
    onFormDataChange({
      selectedSymptoms: formData.selectedSymptoms.filter(s => s !== symptom),
    });
  };

  const handleFileUpload = (event) => {
    onFormDataChange({
      uploadedFiles: [...formData.uploadedFiles, ...Array.from(event.target.files)],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === 'title' && value.length <= maxTitleChars) || (name === 'problemDescription' && value.length <= maxproblemDescriptionChars)) {
      onFormDataChange({
        [name]: value
      });
    }
  };

  const handleNext = () => {
    if (validateFields()) {
      onNext();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddSymptom();
    }
  }

  const availableSymptoms = symptoms.filter(symptom => !formData.selectedSymptoms.includes(symptom.name));

  const handleRemoveFile = (file) => {
    onFormDataChange({
      uploadedFiles: formData.uploadedFiles.filter(f => f !== file),
    });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
        {/* First Half: Title and problemDescription */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="requestDetailsTitle" />
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
            <Box sx={{ position: 'relative', marginBottom: 2 }}>
              <TextField
                label={<LocalizedText id="title" />}
                name="title"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                maxRows={4}
                value={formData.title}
                onChange={handleChange}
                inputProps={{ maxLength: maxTitleChars }}
                error={titleError}
                sx={{ borderColor: titleError ? 'red' : 'inherit' }}
              />
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  backgroundColor: 'white',
                  padding: '0 4px',
                }}
              >
                {`${titleChars}/${maxTitleChars}`}
              </Typography>
            </Box>

            <Box sx={{ position: 'relative', marginBottom: 2 }}>
              <TextField
                label={<LocalizedText id="describeproblemDescription" />}
                name="problemDescription"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                maxRows={8}
                value={formData.problemDescription}
                onChange={handleChange}
                inputProps={{ maxLength: maxproblemDescriptionChars }}
                error={problemDescriptionError}
                sx={{ borderColor: problemDescriptionError ? 'red' : 'inherit' }}
              />
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  backgroundColor: 'white',
                  padding: '0 4px',
                }}
              >
                {`${problemDescriptionChars}/${maxproblemDescriptionChars}`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Second Half: Symptoms and File Upload */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="symptomsSectionTitle" />
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Autocomplete
              options={availableSymptoms}
              getOptionLabel={(option) => option ? option : ""}
              value={selectedSymptom}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
              onChange={(event, newValue) => setSelectedSymptom(newValue)}
              renderInput={(params) => <TextField {...params} label={<LocalizedText id="selectSymptoms" />} />}
              fullWidth
              sx={{ marginRight: 2 }}
              freeSolo
              onKeyDown={handleKeyDown}
            />

            <Button
              onClick={handleAddSymptom}
              variant="contained"
              size="medium"
              sx={{
                backgroundColor: '#282c34',
                color: 'white',
                '&:hover': { backgroundColor: '#404854' },
              }}
            >
              <LocalizedText id="addSymptom" />
            </Button>
          </Box>

          <Box sx={{ marginBottom: 2 }}>
            {formData.selectedSymptoms.map((symptom) => (
              <Chip
                key={symptom}
                label={symptom}
                onDelete={() => handleRemoveSymptom(symptom)}
                sx={{ marginRight: 1 }}
              />
            ))}
          </Box>

          <Typography variant="h6" gutterBottom>
            <LocalizedText id="uploadFilesSectionTitle" />
          </Typography>
          <TextField
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleFileUpload}
            sx={{ marginBottom: 2 }}
          />

          <FilePreviews files={formData.uploadedFiles} handleRemove={handleRemoveFile} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestDetails;
