import React from 'react';
import { Grid, Button } from '@mui/material';
import LocalizedText from './LocalizedText';

const SearchResetButton = ({ handleReset }) => {
  return (
    // <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

    // </Grid>
    <Button
    variant="contained"
    color="secondary"
    onClick={handleReset}
    sx={{
      backgroundColor: '#282c34',
      color: 'white',
      '&:hover': {
        backgroundColor: '#404854',
      },
      minWidth: 'auto',
    }}
  >
    <LocalizedText id="reset" />
  </Button>
  );
};

export default SearchResetButton;
