// Header.js

import React, { useState, useEffect, useContext } from 'react';
import { Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import ClickableDiv from './common/ClickableDiv';
import './../App.scss';
import Hamburger from './common/Hamburger'; // New component for hamburger menu footer
import { isMobileScreen, mobileWidth } from './common/ScreenSizeUtils';
import Logo from './common/Logo';
import LocalizedText from './common/LocalizedText';
import LanguageSelector from './common/LanguageSelector';

const Header = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(isMobileScreen()); // Adjusted width for mobile view
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen()); // Adjusted width threshold
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const NavigateToDoctors = () => {
    return navigate("/doctors");
  };

  const NavigateToArticles = () => {
    return navigate("/articles");
  };

  const NavigateToHome = () => {
    return navigate("/");
  };

  const NavigateToJoin = () =>{
    return navigate("/join");
  }

  const handleMenuClick = () => {
    setIsDrawerOpen(true); // Open the drawer when menu icon is clicked
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false); // Close the drawer
  };

  return (
    <header>
      <Logo />
      <nav>
        <LanguageSelector />
        {isMobile ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div className="header-buttons">
            <div className="header-button flex-column">
              <ClickableDiv onClick={NavigateToDoctors}><LocalizedText id="chooseADoctor" /></ClickableDiv>
            </div>
            <div className="header-button flex-column">
              <ClickableDiv onClick={NavigateToArticles}><LocalizedText id="articles" /></ClickableDiv>
            </div>
            <div className="header-button flex-column">
              <Button
                size="large"
                sx={{
                  backgroundColor: '#282c34',
                  color: 'white',
                  '&:hover': { backgroundColor: '#404854' },
                  minWidth: '200px',
                }}
                onClick={NavigateToJoin}
              >
                <LocalizedText id="joinOurNetwork"/>
              </Button>
            </div>
          </div>
        )}
      </nav>

      {/* Render the HamburgerFooter component for mobile */}
      <Hamburger isOpen={isDrawerOpen} onClose={closeDrawer} />
    </header>
  );
};

export default Header;
