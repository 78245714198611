// LogoPointer.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedText from './LocalizedText';

const Logo = () => {
  const navigate = useNavigate();

  const NavigateToHome = () => {
    return navigate("/");
  };

  return (
    <div className="logo pointer" onClick={NavigateToHome}>
      <img src={process.env.PUBLIC_URL + '/logoEkimMK.png'} alt="logo" />
      <p className="header-button-moto flex-column">
        <LocalizedText id="yourHealthAnytimeAnywhere" />
      </p>
    </div>
  );
};

export default Logo;
