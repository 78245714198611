import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EkimApiClient }  from '../HttpClient/EkimApiClient';
import DynamicTable from './common/DynamicTable';
import SearchForm from './common/SearchForm';
import { Grid, Typography } from '@mui/material';
import LocalizedText from './common/LocalizedText';


const Doctors = () => {
  const location = useLocation();
  const initialSpeciality = location.state?.selectedSpeciality || null;
  const navigate = useNavigate();

  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(initialSpeciality || '');
  const [searchText, setSearchText] = useState('');
  const [doctors, setDoctors] = useState({ items: [], totalCount: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [specialityExists, setSpecialityExists] = useState(true); // State to track if selected speciality exists

  const fetchSpecialities = async () => {
    try {
      const response = await EkimApiClient.specialitiesGetAsync();
      const fetchedSpecialities = response;
      setSpecialities(fetchedSpecialities);

      // Check if initialSpeciality exists in fetchedSpecialities
      const exists = fetchedSpecialities.some(spec => spec.id === initialSpeciality?.id);
      setSpecialityExists(exists);

      // Set selectedSpeciality if it exists in fetchedSpecialities
      if (exists && initialSpeciality) {
        setSelectedSpeciality(initialSpeciality);
        // If initialSpeciality is provided, perform search after fetching specialities
        fetchDoctors();
      } else if (!initialSpeciality) {
        // If no initialSpeciality is provided, setDoctors to empty
        setDoctors({ items: [], totalCount: 0 });
      } else {
        console.warn(`Speciality '${initialSpeciality?.name}' does not exist in the list of specialities.`);
      }
    } catch (error) {
      console.error('Error fetching specialities:', error);
    }
  };
  const fetchDoctors = async () => {
    try {
      const response = await EkimApiClient.doctorsGetAsync({
        params: {
          searchText,
          specialization: selectedSpeciality ? selectedSpeciality.id : '',
          page: currentPage,
          pageSize,
        },
      });
      setDoctors({ items: response.items, totalCount: response.totalCount });
      
      // Do not reset currentPage here; it should remain as the selected page
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };


  useEffect(() => {
    fetchSpecialities();
  }, [initialSpeciality]); // Depend on initialSpeciality to trigger fetching specialities

  useEffect(() => {
    // Perform search only if searchText is not empty and selectedSpeciality exists
    if (searchText.trim() !== '' || selectedSpeciality !== '') {
      if (typingTimeout) {
        clearTimeout(typingTimeout); // Clear previous timeout
      }
      // Set timeout to perform search after 1500ms (1.5 seconds)
      const timeout = setTimeout(() => {
        fetchDoctors();
      }, 1000);
      setTypingTimeout(timeout);
    } else if (!initialSpeciality) {
      // If initialSpeciality is not provided and no search criteria are entered, setDoctors to empty
      setDoctors({ items: [], totalCount: 0 });
    }
  }, [searchText, selectedSpeciality, currentPage, pageSize, initialSpeciality]); // Depend on searchText, selectedSpeciality, currentPage, pageSize, and initialSpeciality

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Fetch data for the new page explicitly
    // This will re-trigger the useEffect for fetching data
    // with the updated currentPage state
    // You may add loading indicators or other UX updates here
  };

  const handleMessage = (doctor) => {
      navigate("/request", {state: { doctor }});
  };

  const handleSearch = (search, speciality) => {
    setSearchText(search);
    setSelectedSpeciality(speciality);
    setCurrentPage(1); // Reset currentPage to 1 on form update
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom>
          <LocalizedText id="findADoctor" />
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center" spacing={2}>
        <SearchForm
          searchText={searchText}
          updateSearchText={setSearchText}
          specialities={specialities}
          selectedSpeciality={selectedSpeciality}
          updateSpeciality={setSelectedSpeciality}
          onSearch={handleSearch}
          specialityExists={specialityExists} // Pass specialityExists to SearchForm
        />
      </Grid>
      <Grid item xs={12}>
        <DynamicTable
          items={doctors.items}
          totalItems={doctors.totalCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          handleMessage={handleMessage}
          pageSize={pageSize}
        />
      </Grid>
    </Grid>
  );
};

export default Doctors;
