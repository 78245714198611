import axiosInstance from "./AxiosClient";

const doctorsGetAsync = async (params) =>{
  try {
      const response = await axiosInstance.get('/doctors', params); // Example endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

const specialitiesGetAsync = async () =>{
  try{
    const response = await axiosInstance.get('/doctors/specialities');
    return response.data;
  }catch(error){
    console.error('Error fetching data:', error);
    throw error;
  }
}

const submitDoctorRequest = async (formData) => {
  try {
    const response = await axiosInstance.postForm('/doctors/join', formData); // Example endpoint
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const translationGetAsync = async () =>{
  try {
      const response = await axiosInstance.get('/translation'); // Example endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

// const initializePaymentPostAsync = async () =>{
//   try {
//       const response = await axiosInstance.post('/payment/initialize'); // Example endpoint
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       throw error;
//     }
// }
const initializePaymentGetAsync = async (email, fullName, phone) =>{
  try {
      const response = await axiosInstance.get('/payment/initialize', {
        params: {
          email,     // Email query param
          fullName,  // Full name query param
          phone      // Phone query param
        }
      }); // Example endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

const redirectToPaymentGatewayPostAsync = async (url, content) =>{
  try {
      const response = await axiosInstance.post(url, content); // Example endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

const submitRequestAsync = async (formData) => {
  try {
    const response = await axiosInstance.postForm('/patientRequest/requestDetails', formData); // Example endpoint
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const profilePictureGetAsync = async (doctorId) =>{
  try {
      const response = await axiosInstance.get(`/doctors/doctor/${doctorId}/profilepicture`); // Example endpoint
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
}

export const EkimApiClient = {
  doctorsGetAsync,
  specialitiesGetAsync,
  translationGetAsync,
  initializePaymentGetAsync,
  redirectToPaymentGatewayPostAsync,
  submitRequestAsync,
  submitDoctorRequest,
  profilePictureGetAsync
};
