import React from 'react';
import { Pagination } from '@mui/material';
import { Card, CardHeader, CardActions, Button, Avatar, Typography, Grid } from '@mui/material';
import LocalizedText from './LocalizedText';
import { EkimApiClient } from '../../HttpClient/EkimApiClient';

const DynamicTable = ({ items = [], totalItems = 0, currentPage, onPageChange, handleMessage, pageSize }) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (event, page) => {
    onPageChange(page); // Propagate page change to parent component (Home)
  };

  const renderCard = (doctor) => {
    return (
      <Grid item key={doctor.id} xs={12} sm={6} md={4}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                alt={doctor.name}
                sx={{ width: '100px', height: '100px' }}
                src={EkimApiClient.profilePictureGetAsync(doctor.id) || "https://mmc.qa/wp-content/uploads/2020/09/doctor-avatar.jpg"}
              />
            }
            title={doctor.name}
            subheader={`Specializations: ${doctor.specializations.map(spec => spec).join(', ')}`}
            // action={
            //   <Typography variant="body2" color="textSecondary" component="p">
            //     Location: {doctor.location.name}
            //   </Typography>
            // }
          />
          <CardActions>
            <Button size="small" sx={{ color: '#71c923', fontWeight: 'bold' }} onClick={() => handleMessage(doctor)}>
              <LocalizedText id="request" />
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  };

  if (items.length === 0) {
    return <p><LocalizedText id="noItemsAvailable" /></p>;
  }

  return (
    <div>
      <Grid container spacing={3}>
        {items.map(doctor => renderCard(doctor))}
      </Grid>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        shape="rounded"
        color="primary"
        size="large"
        showFirstButton
        showLastButton
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );
};

export default DynamicTable;
