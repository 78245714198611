import React from 'react';
import './css/ClickableSmallDiv.scss'; // Import the CSS file for styles


const ClickableSmallDiv = ({ children, onClick }) => {
    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };
  
    return (
      <div className="clickable-small-div" onClick={handleClick}>
        {children}
      </div>
    );
  };
  
  export default ClickableSmallDiv;