import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import PrivacyPolicy from './PrivacyPolicy';
import CookieConsent from './CookieConsent';
import EmpoweredBy from './EmpoweredBy';

const HamburgerFooter = ({ onItemClick }) => {
  return (
    <List>
      <ListItem button onClick={() => onItemClick('privacy')}>
        <ListItemText>
          <PrivacyPolicy />
        </ListItemText>
      </ListItem>
      <ListItem button onClick={() => onItemClick('cookie')}>
        <ListItemText>
          <CookieConsent />
        </ListItemText>
      </ListItem>
      <ListItem button onClick={() => onItemClick('empowered')} style={{ "text-align": "-webkit-center" }}>
        <ListItemText>
          <EmpoweredBy />
        </ListItemText>
      </ListItem>
    </List>
  );
};

export default HamburgerFooter;
