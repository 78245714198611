// CallbackHandler.jsx

import React, { useEffect } from 'react';
import axios from 'axios';

const CallbackHandler = () => {
  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const data = {};
      params.forEach((value, key) => {
        data[key] = value;
      });

      try {
        // Simulating an API request (you might not need this if handling directly)
        const response = await axios.post('/api/Payment/callback', data);
        console.log('Payment callback:', response.data);
      } catch (error) {
        console.error('Error handling callback:', error);
      }
    };

    handleCallback();
  }, []);

  return <div>Processing callback...</div>;
};

export default CallbackHandler;
