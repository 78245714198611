import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Layout from './Pages/Layout';
import Doctors from './Pages/Doctors';
import Articles from './Pages/Articles';
import CookieConsent from './Pages/CookieConsent';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Join from './Pages/doctor/Join';
import { LocalizationProvider } from './Pages/common/LocalizationContext';
import Request from './Pages/patient/Request';
import Loader from './Pages/common/Loader';
import { LoadingContext } from './Pages/common/LoadingContext';
import { setupInterceptors } from './HttpClient/AxiosClient';
import CallbackHandler from './Pages/payment/CallbackHandler';
import ResponseSuccess from './Pages/payment/ResponseSuccess';
import ResponseFailure from './Pages/payment/ResponseFailure';

const App = () => {

  const { loading, startLoading, stopLoading } = useContext(LoadingContext);

  useEffect(() => {
    setupInterceptors(startLoading, stopLoading);
  }, [startLoading, stopLoading]);

  return (
    <Router>
      {
        loading && <Loader />
      }
      <LocalizationProvider>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/doctors" element={<Doctors />} />
            <Route path="/payment/response/success" element={<ResponseSuccess />} />
            <Route path="/payment/response/failure" element={<ResponseFailure />} />
            <Route path="/payment/callback" element={<CallbackHandler />} />
            <Route path="/request" element={<Request />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/cookie-consent" element={<CookieConsent />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/join" element={<Join />} />
          </Routes>
        </Layout>
        </LocalizationProvider>
    </Router>
  );
};

export default App;
