// src/api/AxiosClient.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://localhost:7131/api';
const token = sessionStorage.getItem('access_token');

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});

// Function to set up the loading interceptor
export const setupInterceptors = (startLoading, stopLoading) => {
  // Request interceptor to start the loader
  axiosInstance.interceptors.request.use(
    (config) => {
      startLoading();
      return config;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );

  // Response interceptor to stop the loader
  axiosInstance.interceptors.response.use(
    (response) => {
      stopLoading();
      return response;
    },
    (error) => {
      stopLoading();
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
