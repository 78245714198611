import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Box,
  Grid,
  Typography,
  Avatar,
  Paper,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme
} from '@mui/material';
import LocalizedText from '../common/LocalizedText';
import { EkimApiClient } from '../../HttpClient/EkimApiClient'; // Adjust path as necessary
import FilePreviews from '../patient/FilePreviews'; // Adjust path as necessary

const Join = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    homeAddress: '',
    profilePicture: null,
    diplomaFiles: [],
    agreeTerms: false,
    selectedSpecialities: [],
    biography: '',
  });

  const [specialities, setSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Fetch specialities on mount
  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const specialityList = await EkimApiClient.specialitiesGetAsync();
        setSpecialities(specialityList); // Assuming specialityList is an array of { id, name }
      } catch (error) {
        console.error('Error fetching specialities:', error);
      }
    };

    fetchSpecialities();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'email') {
      validateEmail(value);
    }
    if (name === 'confirmEmail') {
      validateConfirmEmail(value);
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const files = e.target.files;

    if (name === 'profilePicture' && files.length > 0) {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        profilePicture: file,
      }));
      setProfilePicturePreview(URL.createObjectURL(file));
    } else {
      const fileArray = Array.from(files);
      setFormData((prevData) => ({
        ...prevData,
        [name]: [...prevData[name] || [], ...fileArray],
      }));
    }
  };

  const handleRemoveDiplomaFile = (fileToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      diplomaFiles: prevData.diplomaFiles.filter((file) => file !== fileToRemove),
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, agreeTerms: e.target.checked });
  };

  const handleSpecialitiesChange = (event, newValue) => {
    setFormData({ ...formData, selectedSpecialities: Array.from(newValue, item => item.name)});
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!regex.test(email));
  };

  const validateConfirmEmail = (confirmEmail) => {
    setConfirmEmailError(confirmEmail !== formData.email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Additional validation before submitting
    if (!formData.agreeTerms || emailError || confirmEmailError) {
      return; // Do not submit if validation fails
    }

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key !== 'diplomaFiles') {
        formDataToSubmit.append(key, formData[key]);
      } else {
        formData.diplomaFiles.forEach((file) => {
          formDataToSubmit.append('diplomaFiles', file);
        });
      }
    });

    try {
      setLoading(true);
      await EkimApiClient.submitDoctorRequest(formDataToSubmit); // Call the API method to submit data
      setLoading(false);
      setSnackbarMessage('Your request has been successfully submitted!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true); // Open snackbar on success

      // Redirect after 5 seconds
      setTimeout(() => {
        window.location.href = '/'; // Redirect to home
      }, 5000);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
      setSnackbarMessage('There was a problem submitting your request.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true); // Open snackbar on error
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', p: 3 }}>
      <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
      {/* Left Side: Avatar, Name, Specialities */}
      <Box sx={{ flex: 1, mr: 2, padding: isSmallScreen ? '20px' : '0px' }}>
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          {/* Profile Picture Upload */}
          <Avatar
            src={profilePicturePreview}
            sx={{ width: 100, height: 100, margin: '0 auto', borderRadius: '50%' }}
          />
          <input
            type="file"
            name="profilePicture"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="profile-picture-input"
          />
          <label htmlFor="profile-picture-input">
            <Button variant="outlined" component="span" sx={{ mt: 1 }}>
              Upload Profile Picture
            </Button>
          </label>

          {/* First Name and Last Name */}
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ mt: 1, backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
            required
            sx={{ mt: 1, backgroundColor: 'white' }}
            InputLabelProps={{ shrink: true }}
          />

          {/* Specialities */}
          <Autocomplete
            multiple
            options={specialities}
            getOptionLabel={(option) => option.name}
            onChange={handleSpecialitiesChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Specialities" variant="outlined" fullWidth required />
            )}
            sx={{ mt: 1 }}
          />
        </Paper>
      </Box>

      {/* Right Side: Biography, Diploma Upload, Email, Confirm Email */}
      <Box sx={{ flex: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* Biography */}
            <Grid item xs={12}>
              <TextField
                label={<LocalizedText id="biographyLabel" />}
                name="biography"
                value={formData.biography}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                sx={{ position: 'relative', backgroundColor: 'white' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Diploma Upload */}
            <Grid item xs={12}>
              <input
                type="file"
                name="diplomaFiles"
                accept=".pdf,.doc,.docx,.png,.jpg"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="diploma-files-input"
                multiple
              />
              <label htmlFor="diploma-files-input">
                <Button variant="outlined" component="span" sx={{ mt: 1 }}>
                  Upload Diploma
                </Button>
              </label>
              <FilePreviews files={formData.diplomaFiles} handleRemove={handleRemoveDiplomaFile} />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label={<LocalizedText id="emailLabel" />}
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                error={emailError}
                helperText={emailError ? "Invalid email format." : ""}
                fullWidth
                required
                sx={{ position: 'relative', backgroundColor: 'white' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Confirm Email */}
            <Grid item xs={12} sm={6}>
              <TextField
                label={<LocalizedText id="confirmEmailLabel" />}
                name="confirmEmail"
                type="email"
                value={formData.confirmEmail}
                onChange={handleInputChange}
                error={confirmEmailError}
                helperText={confirmEmailError ? "Emails do not match." : ""}
                fullWidth
                required
                sx={{ position: 'relative', backgroundColor: 'white' }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Terms and Conditions */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.agreeTerms}
                    onChange={handleCheckboxChange}
                    required
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the terms and conditions, which state that my personal data may be used for the purposes of the application, including but not limited to processing my registration and providing related services.
                  </Typography>
                }
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading || !formData.agreeTerms || emailError || confirmEmailError}
                sx={{
                  backgroundColor: '#282c34',
                  color: 'white',
                  '&:hover': { backgroundColor: '#404854' },
                  mt: 2,
                }}
              >
                {loading ? 'Submitting...' : <LocalizedText id="submitButtonLabel" />}
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* Snackbar for Success and Error Messages */}
        <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      </Grid>
    </Box>
  );
};

export default Join;
