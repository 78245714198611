// Articles.js

import React from 'react';
import LocalizedText from './common/LocalizedText';

const Articles = () => {
  return (
    <div>
      <h1>
        <LocalizedText id="articles" />
      </h1>
      {/* Add content for Articles page */}
    </div>
  );
};

export default Articles;
