// SearchByText.js
import React from 'react';
import { Grid, FormControl, InputLabel, Input, Button } from '@mui/material';
import LocalizedText from './LocalizedText';

const SearchByText = ({ searchText, setSearchText, onReset }) => {

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleReset = () => {
    setSearchText('');
    onReset();
  };

  return (
    // <Grid container spacing={2} alignItems="center">
    //   <Grid item xs={12} sm={6}>

    //   </Grid>
    // </Grid>
    <FormControl fullWidth>
    <InputLabel htmlFor="search-input">
      <LocalizedText id="searchAtLeast2Characters" />
    </InputLabel>
    <Input
      id="search-input"
      type="text"
      value={searchText}
      onChange={handleSearchChange}
    />
  </FormControl>
  );
};

export default SearchByText;
