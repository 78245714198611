import React, { createContext, useState, useEffect } from 'react';
import { EkimApiClient } from '../../HttpClient/EkimApiClient';

// Create a context object
export const LocalizationContext = createContext();

// LocalizationProvider component manages the context state
export const LocalizationProvider = ({ children }) => {
  // State to hold translations and selected language
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('en'); // Default language

  // Function to fetch translations from backend
  const fetchTranslations = async () => {
    try {
      const response = await EkimApiClient.translationGetAsync(); // Adjust URL as per your backend route
      const translationsFromApi = response; // Assuming response.data is an object with translations
      setTranslations(translationsFromApi);
    } catch (error) {
      console.error('Error fetching translations:', error);
    }
  };

  // Fetch translations on component mount
  useEffect(() => {
    fetchTranslations();
  }, []);

  // Function to switch language
  const switchLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  // Value object to be passed to consumers of the context
  const contextValue = {
    translations,
    language,
    switchLanguage,
  };

  // Provide the context value to the children components
  return (
    <LocalizationContext.Provider value={contextValue}>
      {children}
    </LocalizationContext.Provider>
  );
};
