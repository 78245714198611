// Layout.js

import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import './css/Layout.scss';
import { isMobileScreen, mobileWidth } from './common/ScreenSizeUtils';

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(isMobileScreen()); // Adjusted width for mobile view

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen()); // Adjusted width threshold
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
      <div className='layout'>
        <Header />
        <main>{children}</main>
        {!isMobile && <Footer />}
      </div>
  );
};

export default Layout;
