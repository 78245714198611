import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Grid } from '@mui/material';
import LocalizedText from '../common/LocalizedText';

const PersonalInfo = ({ formData, onBack, onPreview, onFormDataChange }) => {
  const [charCounts, setCharCounts] = useState({});
  const maxCharLimits = {
    firstName: 50,
    lastName: 50,
    placeOfLiving: 100,
    additionalInfo: 6000
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCharCounts({
        firstName: formData.firstName?.length || 0,
        lastName: formData.lastName?.length || 0,
        placeOfLiving: formData.placeOfLiving?.length || 0,
        additionalInfo: formData.additionalInfo?.length || 0,
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (maxCharLimits[name] && value.length <= maxCharLimits[name]) {
      onFormDataChange({
        [name]: value
      });
    } else if (!maxCharLimits[name]) {
      onFormDataChange({
        [name]: value
      });
    }
  };

  return (
    <Box sx={{ padding: 2, overflowX: 'hidden' }}> {/* Ensure no horizontal overflow */}
      <Typography variant="h4" gutterBottom>
        <LocalizedText id="personalInfoTitle" />
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="personalDataSectionTitle" />
          </Typography>
          
          {['firstName', 'lastName', 'placeOfLiving'].map(field => (
            <Box key={field} sx={{ position: 'relative', marginBottom: 2 }}>
              <TextField
                label={<LocalizedText id={field} />}
                name={field}
                variant="outlined"
                fullWidth
                value={formData[field]}
                onChange={handleChange}
                inputProps={{ maxLength: maxCharLimits[field] }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  backgroundColor: 'white', // Background color for readability
                  padding: '0 4px',
                  borderRadius: '4px',
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {`${charCounts[field] || 0}/${maxCharLimits[field]}`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="detailsSectionTitle" />
          </Typography>
          {['age', 'height', 'weight'].map(field => (
            <Box key={field} sx={{ position: 'relative', marginBottom: 2 }}>
              <TextField
                label={<LocalizedText id={field} />}
                name={field}
                variant="outlined"
                type="number"
                fullWidth
                value={formData[field]}
                onChange={handleChange}
                inputProps={{ maxLength: maxCharLimits[field] }}
              />
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="parametersSectionTitle" />
          </Typography>
          {['temperature', 'bloodPressureLow', 'bloodPressureHigh', 'bloodSugar', 'bloodIron', 'respiratoryLevel'].map(field => (
            <TextField
              key={field}
              label={<LocalizedText id={field} />}
              name={field}
              type="number"
              variant="outlined"
              fullWidth
              value={formData[field]}
              onChange={handleChange}
              sx={{ marginBottom: 2 }}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={6} lg={10}>
          <Typography variant="h6" gutterBottom>
            <LocalizedText id="additionalInformationSectionTitle" />
          </Typography>
          <Box sx={{ position: 'relative', marginBottom: 2}}>
            <TextField
              label={<LocalizedText id="additionalInformation" />}
              name="additionalInfo"
              variant="outlined"
              fullWidth
              multiline
              rows={6} // Increase the number of rows for more height
              maxRows={12} // Increase the maximum number of rows if needed
              value={formData.additionalInfo}
              onChange={handleChange}
              inputProps={{ maxLength: maxCharLimits.additionalInfo }}
              // sx={{ minHeight: '200px' }} // Set a minimum height for the text field
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                backgroundColor: 'white', // Background color for readability
                padding: '0 4px',
                borderRadius: '4px',
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {`${charCounts.additionalInfo || 0}/${maxCharLimits.additionalInfo}`}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
        <LocalizedText id="infoHelpDoctor" />
      </Typography>
    </Box>
  );
};

export default PersonalInfo;
