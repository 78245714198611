import React from 'react';
import ClickableDiv from './common/ClickableDiv';
import LocalizedText from './common/LocalizedText';

const Footer = () => {
  const PrivacyPolicyClick = () =>{

  }
  const AlliatesClick = () =>{
    
  }
  const CookieConsentClick = () =>{
    
  }

  return (
    <footer className="footer">
      <div className="footer-content">
      <nav>
            <div className="header-buttons">                      
              <div className="header-button flex-column"> 
                <ClickableDiv onClick={PrivacyPolicyClick}>
                  <LocalizedText id="privacyPolicy" />
                </ClickableDiv>
              </div>   
              <div className="header-button flex-column"> 
              <div className="header-button flex-column"> 
                <ClickableDiv onClick={AlliatesClick} >
                  <div className="empoweredByText">
                    <LocalizedText id="empoweredBy" />
                  </div>
                  <img className="empoweredBy" src={`${process.env.PUBLIC_URL}/alliates_main_logo.png`} />                
                </ClickableDiv>
              </div>   
              </div>   
              <div className="header-button flex-column"> 
              <div className="header-button flex-column"> 
                <ClickableDiv onClick={CookieConsentClick}>
                  <LocalizedText id="cookieConsent" />
                </ClickableDiv>
              </div>   
              </div>   
            </div>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
