import React, { useState, useEffect, useContext } from 'react';
import SwipeableCards from "./common/SwipableCards";
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './css/Home.scss'; // Import the SCSS file for styles
import { isMobileScreen } from './common/ScreenSizeUtils';
import { EkimApiClient } from '../HttpClient/EkimApiClient';
import SearchBySpeciality from './common/SearchBySpeciality';
import LocalizedText from './common/LocalizedText';


const Home = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(isMobileScreen());
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState('');

  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const response = await EkimApiClient.specialitiesGetAsync();
        setSpecialities(response);
      } catch (error) {
        console.error('Error fetching specialities:', error);
      }
    };
    fetchSpecialities();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen()); // Adjusted width threshold
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSearch = () => {
    navigate('/doctors', { state: { selectedSpeciality } });
  };

  const handleSpecialityChange = (speciality) => {
    setSelectedSpeciality(speciality);
    if (!isMobile) {
      // Perform search if not in mobile view
      navigate('/doctors', { state: { selectedSpeciality: speciality } });
    }
  };

  return (
    <div className="home-container">
      {!isMobile ? (
        <div className="top-section">
          <p className='speciality'>
            <LocalizedText id="chooseSpecialty" />
          </p>
          <div className='swipable-cards-wrapper'>
            <SwipeableCards
              items={specialities}
              numToShow={3}
              swipeInterval={5}
              onSelectSpeciality={handleSpecialityChange} // Pass handler to update selectedSpeciality
            />
          </div>
        </div>
      ) : (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={8} sx={{ maxWidth: 'none !important' }}>
            <SearchBySpeciality
              specialities={specialities}
              selectedSpeciality={selectedSpeciality}
              setSelectedSpeciality={handleSpecialityChange}
              sx={{ width: '100%' }} // Ensure dropdown takes full width
            />
          </Grid>
          <Grid item xs={12} md={2} sx={{ maxWidth: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSearch}
              sx={{
                backgroundColor: '#282c34',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#404854',
                },
                minWidth: '100%', // Full width on mobile, adjusted for md viewport
              }}
            >
              <LocalizedText id="Search" />
            </Button>
          </Grid>
        </Grid>
      )}

      <div className="bottom-section">
        <div className="left-side">
          <h1><LocalizedText id="qualityHealthcareAtYourFingertips"/></h1>
          <p><LocalizedText id="yourHealthAndWell-BeingAreOurTopPriority"/></p>
          <div><LocalizedText id="connectWithExperiencedCertifiedDoctorsInMinutesRightFromYourCurrentLocation"/>.</div>
          <div className="divButton">
            <Button
              size="large"
              onClick={() => navigate("/doctors")}
            >
              <LocalizedText id="sendYourQuestion"/>
            </Button>
          </div>
        </div>

        <div className="right-side">
          <img src={`${process.env.PUBLIC_URL}/doctorImage.jpg`} alt="Example" />
        </div>
      </div>
    </div>
  );
};

export default Home;
