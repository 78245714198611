// SearchForm.js
import React from 'react';
import { Grid } from '@mui/material';
import SearchByText from './SearchByText';
import SearchBySpeciality from './SearchBySpeciality';
import SearchResetButton from './SearchResetButton';

const SearchForm = ({ specialities, onSearch, searchText, updateSearchText, updateSpeciality, selectedSpeciality }) => {

  return (
    <>
      {/* <Grid container spacing={2} alignItems="center"> */}
        <Grid item xs={12}  sm={5} md={5} sx={{ maxWidth: 'none !important' }}>
          <SearchByText
            searchText={searchText}
            setSearchText={updateSearchText}
          />
        </Grid>
        <Grid item  xs={12} sm={5} md={5} sx={{ maxWidth: 'none !important' }}>
          <SearchBySpeciality
            specialities={specialities}
            selectedSpeciality={selectedSpeciality}
            setSelectedSpeciality={updateSpeciality}
          />
        </Grid>
        <Grid item  sm={2} width={100}>
          <SearchResetButton
            handleReset={() => {
              updateSearchText('');
              updateSpeciality('');
            }}
          />
        </Grid>
      {/* </Grid> */}
    </>
  );
};

export default SearchForm;
