// src/components/Loader.js
import React from 'react';
import './css/Loader.scss'; // Optional: Include styling if needed

const Loader = () => (
  <div className="loader">
    <div className="spinner"></div> {/* Customize your spinner */}
  </div>
);

export default Loader;
