// ./common/EmpoweredBy.js

import React from 'react';
import LocalizedText from './LocalizedText';

const EmpoweredBy = () => {
  return (
    <div className="header-button flex-column">
      <div className="empoweredByText">
        <LocalizedText id="empoweredBy" />
      </div>
      <img className="empoweredBy" src={`${process.env.PUBLIC_URL}/alliates_main_logo.png`} />
    </div>
  );
};

export default EmpoweredBy;
