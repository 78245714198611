// src/pages/ResponseSuccess.js
import React from 'react';

const ResponseSuccess = () => {
    return (
        <div>
            <h1>Payment Successful!</h1>
            <p>Your payment was processed successfully.</p>
        </div>
    );
};

export default ResponseSuccess;
