import React from 'react';
import { Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import ClickableDiv from './ClickableDiv';
import { useNavigate } from 'react-router-dom';
import { isMobileScreen } from './ScreenSizeUtils';
import Footer from './../Footer'; // Import the Footer component
import HamburgerFooter from './HamburgerFooter';
import Logo from './Logo';
import LocalizedText from './LocalizedText';

const Hamburger = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const NavigateToDoctors = () => {
    onClose(); // Close the drawer when navigating
    return navigate("/doctors");
  };

  const NavigateToArticles = () => {
    onClose(); // Close the drawer when navigating
    return navigate("/articles");
  };

  const NavigateToHome = () => {
    onClose(); // Close the drawer when navigating
    return;
  };

  const NavigateToJoin = () => {
    onClose(); // Close the drawer when navigating
    return navigate("/join");
  };

  const handleItemClick = (item) => {
    onClose();
    var path;
    switch (item) {
      case 'privacy':
        path = "/privacy-policy"
        break;
      case 'cookie':
        path = "/cookie-consent"
        break;
      case 'empowered':
        path = "/empoweredBy"
        break;
      default:
        break;
    }
    return navigate(path);
  };

  const isMobile = isMobileScreen(); 

  return (
    <>
      {isMobile ? 
        <Drawer anchor="left" open={isOpen} onClose={onClose}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', alignItems: 'center' }}>
            <List style={{ flexGrow: 1 }}>
              <ListItem onClick={NavigateToHome}>
                <Logo />
              </ListItem>
              <ListItem button onClick={NavigateToDoctors}>
                <ListItemText primary="Choose a Doctor" />
              </ListItem>
              <ListItem button onClick={NavigateToArticles}>
                <ListItemText primary="Articles" />
              </ListItem>
              <ListItem button onClick={NavigateToJoin}>
                <LocalizedText id="joinOurNetwork" />
              </ListItem>
            </List>
            <Divider />
            <HamburgerFooter onItemClick={handleItemClick} />
          </div>
        </Drawer> 
        : null
      }
    </>
  );
};

export default Hamburger;
