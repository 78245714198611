import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import ClickableSmallDiv from './ClickableSmallDiv';
import './css/SwipableCards.scss'; // Import the SCSS file for styles

const SwipableCards = ({ items, numToShow, swipeInterval, onSelectSpeciality }) => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for forward, -1 for backward
  const [hovered, setHovered] = useState(false); // State to track hover state
  const [paused, setPaused] = useState(false); // State to track pause on hover

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        setIndex((prevIndex) => {
          const newIndex = prevIndex + direction;
          if (newIndex < 0) {
            setDirection(1); // Change direction to forward if reaching start
            return 0; // Ensure the index stays within bounds
          } else if (newIndex >= Math.ceil(items.length / numToShow)) {
            setDirection(-1); // Change direction to backward if reaching end
            return Math.ceil(items.length / numToShow) - 1; // Ensure the index stays within bounds
          }
          return newIndex;
        });
      }
    }, swipeInterval * 1000); // Autoplay interval in milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [direction, paused, numToShow, swipeInterval]);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const handleMouseEnter = () => {
    setHovered(true);
    setPaused(true); // Pause swiping on hover
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setPaused(false); // Resume swiping when not hovered
  };

  const handleClickSpeciality = (speciality) => {
    onSelectSpeciality(speciality);
  };

  return (
    <>
      {items !== undefined ? (
        <Box
          className="swipable-cards-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <SwipeableViews index={index} onChangeIndex={handleChangeIndex} enableMouseEvents>
            {[...Array(Math.ceil(items.length / numToShow)).keys()].map((i) => (
              <Box key={i} className="card-container" display="flex">
                {items.slice(i * numToShow, i * numToShow + numToShow).map((spec) => (
                  <Box
                    key={spec.id}
                    className="card"
                    onClick={() => handleClickSpeciality(spec)}
                  >
                    <ClickableSmallDiv>
                      {spec.name}
                    </ClickableSmallDiv>
                  </Box>
                ))}
              </Box>
            ))}
          </SwipeableViews>
        </Box>
      ) : null}
    </>
  );
};

export default SwipableCards;
