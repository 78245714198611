import React, { useState, useContext } from 'react';
import './css/LanguageSelector.scss'; // Assuming your SCSS file is named styles.scss
import ClickableDiv from './ClickableDiv';
import { LocalizationContext } from './LocalizationContext';
import LocalizedText from './LocalizedText';

const LanguageSelector = () => {
    const { translations, switchLanguage } = useContext(LocalizationContext);
    const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    switchLanguage(language);
    // Add your logic for changing the language here
  };

  return (
    <div className="language-selector header-button">
      <>
        <ClickableDiv
          onClick={() => handleLanguageChange('en')}
          className={selectedLanguage === 'en' ? 'selected' : ''}
        >
          <LocalizedText id="EN" />
        </ClickableDiv>
        <ClickableDiv className={'leftRightMargin5px'}>|</ClickableDiv>
        <ClickableDiv
          onClick={() => handleLanguageChange('mk')}
          className={selectedLanguage === 'mk' ? 'selected' : ''}
        >
          <LocalizedText id="MK" />
        </ClickableDiv>
      </>
    </div>
  );
};

export default LanguageSelector;

