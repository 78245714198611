import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import LocalizedText from '../common/LocalizedText';

// Regex for validating email format
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Preview = ({ formData, onBack, onConfirm, disableSaveButton, onFormDataChange }) => {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailFormatValid, setIsEmailFormatValid] = useState(true);

  // Effect hook to validate email and match confirmation
  useEffect(() => {
    // Validate email format
    const emailIsValid = emailRegex.test(email);
    setIsEmailFormatValid(emailIsValid);

    // Check if both email fields match and are valid
    if (emailIsValid && email === confirmEmail) {
      setIsEmailValid(true);
      disableSaveButton(false); // Enable Pay button

      updateFormEmail(email); // Update formData with the valid email
    } else {
      setIsEmailValid(false);
      disableSaveButton(true); // Disable Pay button
    }
  }, [email, confirmEmail, disableSaveButton]);

  // Update the formData email field when the email is valid
  const updateFormEmail = (email) => {
    onFormDataChange({
      email: email // Set the email value in formData
    });
  };

  const handleConfirm = () => {
    // Print the form data to the console
    console.log('Form data to be sent:', formData);

    // Call the onConfirm callback to proceed to the next step or finalize the process
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, padding: 2 }}>
      {/* Preview Section */}
      <Box sx={{ flex: 3, paddingRight: 2 }}>
        <Typography variant="h4" gutterBottom>
          <LocalizedText id="previewTitle" />
        </Typography>

        <Typography variant="h6" gutterBottom>
          <LocalizedText id="requestDetails" />
        </Typography>

        {/* Displaying form data */}
        {formData.title && (
          <Typography variant="body1">
            <strong><LocalizedText id="title" />:</strong> {formData.title}
          </Typography>
        )}
        {formData.problem && (
          <Typography variant="body1">
            <strong><LocalizedText id="describeProblem" />:</strong> {formData.problem}
          </Typography>
        )}
        {formData.selectedSymptoms.length > 0 && (
          <Typography variant="body1">
            <strong><LocalizedText id="symptoms" />:</strong> {formData.selectedSymptoms.join(', ')}
          </Typography>
        )}
        {formData.uploadedFiles.length > 0 ? (
          <Typography variant="body1">
            <strong><LocalizedText id="uploadedFiles" />:</strong> {formData.uploadedFiles.map(file => file.name).join(', ')}
          </Typography>
        ) : (
          <Typography variant="body1">
            <strong><LocalizedText id="uploadedFiles" />:</strong> <LocalizedText id="noFiles" />
          </Typography>
        )}

        {/* Personal information */}
        <Typography variant="h6" gutterBottom>
          <LocalizedText id="personalInfo" />
        </Typography>
        {formData.firstName && (
          <Typography variant="body1">
            <strong><LocalizedText id="firstName" />:</strong> {formData.firstName}
          </Typography>
        )}
        {formData.lastName && (
          <Typography variant="body1">
            <strong><LocalizedText id="lastName" />:</strong> {formData.lastName}
          </Typography>
        )}
        {formData.age && (
          <Typography variant="body1">
            <strong><LocalizedText id="age" />:</strong> {formData.age}
          </Typography>
        )}
        {formData.height && (
          <Typography variant="body1">
            <strong><LocalizedText id="heightOptional" />:</strong> {formData.height}
          </Typography>
        )}
        {formData.weight && (
          <Typography variant="body1">
            <strong><LocalizedText id="weight" />:</strong> {formData.weight}
          </Typography>
        )}
        {formData.placeOfLiving && (
          <Typography variant="body1">
            <strong><LocalizedText id="placeOfLiving" />:</strong> {formData.placeOfLiving}
          </Typography>
        )}
        {formData.temperature && (
          <Typography variant="body1">
            <strong><LocalizedText id="temperature" />:</strong> {formData.temperature}
          </Typography>
        )}
        {formData.bloodPressureLow && (
          <Typography variant="body1">
            <strong><LocalizedText id="bloodPressureLow" />:</strong> {formData.bloodPressureLow}
          </Typography>
        )}
        {formData.bloodPressureHigh && (
          <Typography variant="body1">
            <strong><LocalizedText id="bloodPressureHigh" />:</strong> {formData.bloodPressureHigh}
          </Typography>
        )}
        {formData.bloodSugar && (
          <Typography variant="body1">
            <strong><LocalizedText id="bloodSugarOptional" />:</strong> {formData.bloodSugar}
          </Typography>
        )}
        {formData.bloodIron && (
          <Typography variant="body1">
            <strong><LocalizedText id="bloodIronLevelOptional" />:</strong> {formData.bloodIron}
          </Typography>
        )}
        {formData.respiratoryLevel && (
          <Typography variant="body1">
            <strong><LocalizedText id="respiratoryLevelOptional" />:</strong> {formData.respiratoryLevel}
          </Typography>
        )}

        <Typography variant="h6" gutterBottom>
          <LocalizedText id="additionalInformation" />
        </Typography>
        {formData.additionalInfo && (
          <Typography variant="body1">
            {formData.additionalInfo}
          </Typography>
        )}
      </Box>

      {/* Email Input Section */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 2 }}>
        <LocalizedText id="pleaseConfirmEmail" />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!isEmailFormatValid && email.length > 0}
          helperText={
            !isEmailFormatValid && email.length > 0 ? 'Invalid email format' : ''
          }
        />

        <TextField
          label="Confirm Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          error={email !== confirmEmail && confirmEmail.length > 0}
          helperText={
            email !== confirmEmail && confirmEmail.length > 0
              ? 'Emails do not match'
              : ''
          }
        />
      </Box>
    </Box>
  );
};

export default Preview;
