// ./common/CookieConsent.js

import React from 'react';
import ClickableDiv from './ClickableDiv';
import LocalizedText from './LocalizedText';

const CookieConsent = () => {
  const CookieConsentClick = () => {
    // Handle Cookie Consent click
  };

  return (
    <div className="header-button flex-column">
      <ClickableDiv onClick={CookieConsentClick}>
        <LocalizedText id="cookieConsent" />
      </ClickableDiv>
    </div>
  );
};

export default CookieConsent;
