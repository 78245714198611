import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';

const FilePreviews = ({ files = [], handleRemove }) => {
  const truncateFileName = (fileName) => {
    const maxLength = 10;
    const extension = fileName.split('.').pop();
    const name = fileName.slice(0, -extension.length - 1);
    
    return name.length > maxLength
      ? `${name.slice(0, maxLength)}...${extension}`
      : fileName;
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
      {files.length > 0 ? (
        files.map((file, index) => {
          const fileUrl = URL.createObjectURL(file);
          const isImage = file.type.startsWith('image/');
          const isPDF = file.type === 'application/pdf';

          return (
            <Box
              key={index}
              sx={{ position: 'relative', width: 120, height: 120, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              {isImage && (
                <img
                  src={fileUrl}
                  alt="Preview"
                  style={{ width: '70px', height: '70px', objectFit: 'cover' }}
                />
              )}
              {isPDF && (
                <Box
                  sx={{ width: 70, height: 70, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f5f5f5' }}
                >
                  <PdfIcon sx={{ fontSize: 70, color: '#d32f2f' }} />
                </Box>
              )}

              <IconButton
                onClick={() => handleRemove(file)}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'red',
                  backgroundColor: 'white',
                  '&:hover': { backgroundColor: '#f1f1f1' }
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography variant="caption" sx={{ marginTop: 1, textAlign: 'center', maxWidth: '100%' }}>
                {truncateFileName(file.name)}
              </Typography>
            </Box>
          );
        })
      ) : (
        <Typography>No files uploaded</Typography>
      )}
    </Box>
  );
};

export default FilePreviews;
